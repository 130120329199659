import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import '@coreui/icons/css/coreui-icons.min.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import './scss/style.css';
import { DefaultLayout } from './containers';
import { Login, Page404, Page500 } from './projectNulla/catalogs/Pages';
import { connect } from 'react-redux';
import { languageActions, accountActions } from "./projectNulla/redux-modules/actions";
import config from './config/app';
import WebPush from './WebPush';
import { payloadFromSubscription } from './Utility';
import Dialog from './projectNulla/components/dialog/Dialog';
import Auth from './projectNulla/auth/Auth';
import 'boxicons';
import useLocationTracker from './projectNulla/hooks/useLocationTracker';
const applicationServerPublicKey = "BJ9ENN1q-D_TDL_kt634h683xN6tttl0c008DpaE4JtOcCZFgDuP1sTlDH-5pPsmRd8YG98myAnVcFFg37HpfOU";
import { getToken } from './services/callService';
import { Device } from "twilio-client";
import { useDispatch } from 'react-redux';
import { updateDimensions } from './projectNulla/redux-modules/services/mobileService';
import NoInternetAlert from './projectNulla/NoInternetAlert';

const App = ({ account, fetchUserPermissions, getLanguages }) => {
    const isMobile = updateDimensions()
    const [twilioToken, setTwilioToken] = useState(null);
    const dispatch = useDispatch();
    const { location } = useLocationTracker(account.token);
    const authenticated = account.token || !!(account.account && account.account.token)

    const [subscriveUserEnabled, setSubscriveUserEnabled] = useState(true);

    const [subscription, setSubscription] = useState({
        endpoint: `${config.API}subscription/save-subscription`
    });

    const onUpdateSubscriptionOnServer = (newSubscription) => {
        const payload = payloadFromSubscription(newSubscription);

        if (localStorage.getItem('account')) {
            const SERVER_URL = `${config.API}subscription/save-subscription`;
            fetch(SERVER_URL, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem('account')).token
                },
                body: JSON.stringify(payload)
            });
        }

        setSubscription(newSubscription);
    }

    const onSubscriptionFailed = (error) => {
        console.log("onSubscriptionFailed:", error);
    }

    function getTwilioToken(account) {
        getToken(account)
        .then(({ data }) => {
            setTwilioToken(data.token)
        })
    }

    useEffect(() => {
        if (account.token) {

            getLanguages()
            getTwilioToken(account)
            fetchUserPermissions(account.token)

            const intervalId = setInterval(() => {
                getTwilioToken(account)
            }, 30 * 60 * 1000);

            return () => clearInterval(intervalId);

        }
    }, [account.token]);
  

    useEffect(() => {

        const initializeDevice = () => {

            if (twilioToken) {

                // Create a new Twilio Device instance
                const newDeviceInstance = Device.setup(twilioToken, {
                    audioConstraints: true,
                    allowIncomingWhileBusy: true,
                    enableRingingState: true,
                    debug: true
                });

                newDeviceInstance.on('ready', () => {
                    dispatch({ 
                        type: 'SET_TWILIO_DEVICE', 
                        payload: newDeviceInstance 
                    })
                });

                newDeviceInstance.on('offline', () => {
                    console.log('offline')
                });

                newDeviceInstance.on('tokenAboutToExpire', () => {
                    getTwilioToken(account)
                });
            
                newDeviceInstance.on('tokenExpired', () => {
                    getTwilioToken(account)
                });

                newDeviceInstance.on('error', error => {
                    console.log(error, 'error')
                    console.log(`${error.message} (${error.code})`);

                    if (error.code == 31205) {
                        getTwilioToken(account)
                    }
                
                });
            }
        };

        // Initial device setup
        initializeDevice();

    }, [twilioToken]);

    // useEffect(() => {

    //     // Handle visibility change
    //     const handleVisibilityChange = () => {
    //         if (isMobile && document.visibilityState === "visible") {
    //             window.location.reload();  // Reloads the page when it becomes visible again
    //         }
    //     };

    //     // Add event listener for visibility change
    //     document.addEventListener('visibilitychange', handleVisibilityChange);

    //     return () => {
    //         document.removeEventListener('visibilitychange', handleVisibilityChange);
    //     };

    // }, []); // Empty dependency array ensures this effect runs once on mount


    const isIos = Boolean(navigator.userAgent.match(/iPhone|iPad|iPod/i));

    return (
        <React.Fragment>
            <Dialog />
            <NoInternetAlert/>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/login" render={() => (
                        authenticated ? <Redirect to="/" /> : <Login />
                    )} />
                    <Route exact path="/register" render={() => (
                        authenticated ? <Redirect to="/" /> : <Auth />
                    )} />
                    <Route exact path="/forgot_password" render={() => (
                        authenticated ? <Redirect to="/" /> : <Auth />
                    )} />
                    <Route path="/user/activate" name="User Activate" component={Auth} />
                    <Route
                        path="/"
                        render={({ location }) => {
                            if (authenticated) {
                                return <DefaultLayout/>;
                            } else if (location.pathname !== '/register' && location.pathname !== '/forgot_password') {
                                return <Redirect to="/login" />
                            }
                        }}
                    />
                    <Route path="/" name="Home" component={DefaultLayout} />
                    <Route exact path="/404" name="Page 404" component={Page404} />
                    <Route exact path="/500" name="Page 500" component={Page500} />
                </Switch>
                {!isIos && (
                    <WebPush
                        subscriveUserEnabled={subscriveUserEnabled}
                        applicationServerPublicKey={applicationServerPublicKey}
                        onSubscriptionFailed={onSubscriptionFailed}
                        onUpdateSubscriptionOnServer={onUpdateSubscriptionOnServer}
                    />
                )}
            </BrowserRouter>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        account: state.account
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserPermissions: (jwt) => dispatch(accountActions.fetchUserPremissions(jwt)),
        getLanguages: () => dispatch(languageActions.getLanguages()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);