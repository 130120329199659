import {SIGN_TYPES} from '../../types/index'

let acc = {
  loading: false,
  data: null,
  err: null,
  verify: null,
  forgotPassword: null,
  resetPassword: null,
  resendCode: null,
  activateInvitation: null,
  userGroup: {
    permissions: []
  }
};

if(localStorage.account) {
  acc = {...JSON.parse(localStorage.account), ...acc}
}
const account = (state = acc, action = {}) => {
  switch (action.type) {
    case SIGN_TYPES.SIGN_IN:
      return {
        ...state,
        ...signIn(action)
      };
    case SIGN_TYPES.SIGN_OUT: {
      return {
        ...state,
        loading: false,
        data: null,
        err: null,
        verify: null,
        forgotPassword: null,
        resetPassword: null,
        resendCode: null,
        token: null,
        socketToken: null,
        activateInvitation: null,
        userGroup: {
          permissions: []
        }
      };
    }
    case SIGN_TYPES.FETCH_USER_PERMISSIONS_P: {
      return {
        ...state,
        loading: true
      }
    }
    case SIGN_TYPES.FETCH_USER_PERMISSIONS_F: {
      return {
        ...state,
        loading: false,
        userGroup: action.payload
      }
    }
    case SIGN_TYPES.FETCH_USER_PERMISSIONS_R: {
      return {
        ...state,
        loading: false
      }
    }
    case SIGN_TYPES.SIGN_UP_P: {
      return {
        ...state,
        loading: true
      }
    }
    case SIGN_TYPES.SIGN_UP_F: {
      return {
        ...state,
        data: action.payload,
        err: null,
        loading: true
      }
    }
    case SIGN_TYPES.SIGN_UP_R: {
      return {
        ...state,
        err: action.payload,
        loading: false
      }
    }
    case SIGN_TYPES.VERIFY_P: {
      return {
        ...state,
        loading: true
      }
    }
    case SIGN_TYPES.VERIFY_F: {
      return {
        ...state,
        verify: action.payload,
        err: null,
        loading: true
      }
    }
    case SIGN_TYPES.VERIFY_R: {
      return {
        ...state,
        err: action.payload,
        loading: false
      }
    }
    case SIGN_TYPES.FORGOT_PASSWORD_P: {
      return {
        ...state,
        loading: true
      }
    }
    case SIGN_TYPES.FORGOT_PASSWORD_F: {
      return {
        ...state,
        forgotPassword: action.payload,
        err: null,
        loading: true
      }
    }
    case SIGN_TYPES.FORGOT_PASSWORD_R: {
      return {
        ...state,
        err: action.payload,
        loading: false
      }
    }
    case SIGN_TYPES.RESET_PASSWORD_P: {
      return {
        ...state,
        loading: true
      }
    }
    case SIGN_TYPES.RESET_PASSWORD_F: {
      return {
        ...state,
        resetPassword: action.payload,
        err: null,
        loading: true
      }
    }
    case SIGN_TYPES.RESET_PASSWORD_R: {
      return {
        ...state,
        err: action.payload,
        loading: false
      }
    }

    case SIGN_TYPES.ACTIVATE_INVITATION_P: {
      return {
        ...state,
        loading: true
      }
    }
    case SIGN_TYPES.ACTIVATE_INVITATION_F: {
      return {
        ...state,
        activateInvitation: action.payload,
        err: null,
        loading: true
      }
    }
    case SIGN_TYPES.ACTIVATE_INVITATION_R: {
      return {
        ...state,
        err: action.payload,
        loading: false
      }
    }

    case SIGN_TYPES.RESEND_CODE_P: {
      return {
        ...state,
        loading: true
      }
    }
    case SIGN_TYPES.RESEND_CODE_F: {
      return {
        ...state,
        resendCode: action.payload,
        err: null,
        loading: true
      }
    }
    case SIGN_TYPES.RESEND_CODE_R: {
      return {
        ...state,
        err: action.payload,
        loading: false
      }
    }

    case SIGN_TYPES.CLEAR_ERROR: {
      return {
        ...state,
        data: null,
        err: null,
        verify: null,
        resendCode: null,
        forgotPassword: null,
        resetPassword: null,
        activateInvitation: null,
        loading: false
      }
    }
    default:
      return state;
  }
};

const signIn = action => {
  localStorage.account = JSON.stringify(action.payload);
  return action.payload
};

const signOut = () => {
  localStorage.account = JSON.stringify({});
  return {}
};


export {
  account
}
